/**
 * Script for hiding elements depending on input
 *
 * @author Marcus Olsson
 */
(function(){
	$(document).ready(function(){

		var $hideElements = $('.js-show-hide');

		runCheckbox();

		$hideElements.on('change', function(){
			runCheckbox();
		});

		$hideElements.on('click', function(e){
			if($(this).is('a') || $(this).is('button')) {
				e.preventDefault();
				runLink($(this));
			}
		});

		function runCheckbox() {
			$.each($hideElements, function(){

				// The hide and show
				if($(this).attr('data-hide') && $(this).is(':checked')) {
					hideElement($(this).data('hide'));
				}
				if($(this).attr('data-show') && ($(this).is(':checked') || $(this).hasClass('active'))) {
					showElement($(this).data('show'));
				}

				// The hide-check
				if($(this).attr('data-hide-check') && $(this).is(':checked')) {
					hideElement($(this).data('hide-check'));
				} else if($(this).attr('data-hide-check') && $(this).not(':checked')) {
					showElement($(this).data('hide-check'));
				}

				// The hide-select
				if($(this).attr('data-show-select-value') && $(this).val() == $(this).attr('data-show-select-value')) {
					showElement($(this).data('show-select'));
				} else if($(this).attr('data-show-select-value') && $(this).val() != $(this).attr('data-show-select-value')) {
					hideElement($(this).data('show-select'));
				} else if($(this).attr('data-show-select-combo')) {
					var selected = $(this).val();
					var options = $(this).data('show-select-combo');
					options = options.split('|');
					$.each(options, function(i, v){
						var val = v.split(':');
						if (selected == val[0]) {
							showElement(val[1]);
						} else {
							hideElement(val[1]);
						}
					});
				}

				// The show-check
				if($(this).attr('data-show-check') && $(this).is(':checked')) {
					showElement($(this).data('show-check'));
				} else if($(this).attr('data-show-check') && $(this).not(':checked')) {
					hideElement($(this).data('show-check'));
				}
			});
		}

		function runLink(element) {
			console.log('run!');
			if(element.attr('data-hide')) {
				hideElement(element.data('hide'));
			}
			if(element.attr('data-show')) {
				showElement(element.data('show'));
			}
			if(element.hasClass('js-show-hide-remove')) {
				element.remove();
			}
		}

		function hideElement(element) {
			$('.' + element).hide();
		}

		function showElement(element) {
			$('.' + element).show();
		}
	});
})(jQuery);
