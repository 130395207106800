/**
 * Functions to perform some calculus on sales
 * @author Marcus Olsson
 */
;(function () {

    var sellers = 20;
    var tea = 10;
    var coffee = 10;

    var teaPrice = 80;
    var coffeePrice = 130;

    if ($('.js__app--calculus-result').length) {
        draw();
    }

    $('.js__app--calculus-slider').on('change input', function(){

        var type = $(this).data('type');

        if (type == 'tea') {
            tea = $(this).val();
        } else if (type == 'coffee') {
            coffee = $(this).val();
        } else if (type == 'sellers') {
            sellers = $(this).val();
        }

        draw();
    });

    function draw() {

        var sum = ((tea * teaPrice) + (coffee * coffeePrice)) * sellers * 0.33;
        sum = Math.round(sum);

        // Draw the inputed values
        $('.js__app--calculus-sellers').text(sellers);
        $('.js__app--calculus-tea').text(tea);
        $('.js__app--calculus-coffee').text(coffee);

        // Result
        $('.js__app--calculus-result').text(sum);
    }
})();
