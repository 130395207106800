/**
 * Simple confirmation script
 * @author Marcus Olsson
 */
;(function () {
    var button = $('.js__app--confirm');
    button.on('click', function(e){
        e.preventDefault();

        var text = 'Är du säker?';

        if ($(this).data('confirm-text')) {
            text = $(this).data('confirm-text');
        }

        var value = confirm(text);
        if (value == true) {
            $($(this).attr('href')).submit();
        }

        return false;
    });
})();
