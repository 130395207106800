/**
 * Dynamically add/remove table-input
 *
 * @author Marcus Olsson
 */
(function(){
    var tableRowCounter = null;
    var target = '#' + $('table').eq(0).attr('id');
    target = $(target);

    updateTableRowCounter(target);

    // When .js-table-add-row
    $('.js-table-add-row').on('click', function(e){
        e.preventDefault();

        // Generate random string
        var random = makeRandomString(14);

        var html = $(target.data('source')).html();
        var replaceer = new RegExp('%random%', 'g');

        html = html.replace(replaceer, random);

        target.find('tbody').append(html);

        updateTableRowCounter(target);

        return false;

    });

    // When .js-table-remove-row
    $('table').on('click', '.js-table-remove-row', function(e) {
        e.preventDefault();

        if(tableRowCounter <= 1) {
            // Launch modal?
            alert('Du måste ha minst en fond');

            return false;
        }

        var parent = $(this).closest('tr');
        parent.remove();

        updateTableRowCounter(target);

        return false;
    });

    function updateTableRowCounter(target) {
        tableRowCounter = $(target).find('tbody > tr').length;

        // Transmitt event
        $.event.trigger({
        	type: "dynamic-field-added",
        	element: $(this),
        	time: new Date()
        });
    }

    function makeRandomString(input) {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

        for( var i=0; i < input; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
})(jQuery);
