/**
 * FB-functions
 */
;(function(){
    // On .js-fb-lead click
    $('.js-fb-lead').on('click', function(){
        if (typeof fbq == 'function') {
            fbq('track', 'Lead');
        }
    });
})($);
