$(function() {

    var options = {
        // maxOptions: 10
    };

    var selectize = $('select.js-selectize:not(.selectized)').selectize(options);

    // Handle fireing of selectize when new select added
    $(document).on('dynamic-field-added', function(event){
        // selectize.clearCache();
        selectize = $('select.js-selectize:not(.selectized)').selectize(options);
    });

    // Print page
    $('.js-print').click(function(e){
        e.preventDefault();
        window.print();
        return false;
    });

    // Load button
    $('.js-loading').click(function(){
        $(this).addClass('loading');
        $(this).addClass('disabled');
        return true;
    });

    // Get window width
    var windowWidth = $(window).width();

    // Handle positioned images
    $(window).ready(function(){
        justifyColumns();
        fixTextAlignment();
    });

    function justifyColumns() {
        var columns = $('.js-info .info-column');
        var images = $('.js-info .column img');
        var targetHeight = 0;
        var targetColumn = $('.js-target-column');

        images.removeClass('positioned');
        targetColumn.css('height', null);

        if (!images.eq(0)[0].complete) {
            images.eq(0).on('load', function() {
                handleJustifyColumns();
            });
        } else {
            handleJustifyColumns();
        }

        function handleJustifyColumns() {
            if (windowWidth >= 480) {
                $.each(columns, function(i, v){
                    if ($(this).height() > targetHeight) {
                        if (i >= 2) {
                            targetHeight = $(this).height();
                        } else {
                            targetHeight = $(this).height() +70;
                        }
                    }

                    targetColumn.css('height', targetHeight);
                });

                images.addClass('positioned');
                targetColumn.css('height', targetHeight);

                // Correct the two last columns
                $.each(columns, function(i, v){
                    if (i >= 2) {
                        $(this).css('height', $(this).height()-55);
                    }
                });

                // Switch out the image widths
                $.each(images, function(i, v){
                    if ($(this).hasClass('w40')) {
                        $(this).removeClass('w40').addClass('w30');
                    } else if ($(this).hasClass('w50')) {
                        $(this).removeClass('w50').addClass('w40');
                    } else if ($(this).hasClass('w70')) {
                        $(this).removeClass('w70').addClass('w60');
                    }
                });
            } else {
                // Switch out the image widths
                $.each(images, function(i, v){
                    if ($(this).hasClass('w30')) {
                        $(this).removeClass('w30').addClass('w40');
                    } else if ($(this).hasClass('w40')) {
                        $(this).removeClass('w40').addClass('w50');
                    } else if ($(this).hasClass('w60')) {
                        $(this).removeClass('w60').addClass('w70');
                    }
                });
            }
        }
    }

    function fixTextAlignment() {
        var text = $('.js-steps .step');
        if (windowWidth >= 480) {
            text.removeClass('text-center').addClass('text-right');
        } else {
            text.removeClass('text-right').addClass('text-center');
        }
    }

    // Sticky
    if ($('.js-main-nav').length) {

        var offsetNav = $('.js-main-nav').offset().top;
        var heightNav = $('.js-main-nav').outerHeight();
        var marginTopMain = 0;
        var windowTop = $(window).scrollTop();
        $(window).scroll(function(){
            windowTop = $(window).scrollTop();
            if(windowTop > offsetNav) {
                $('.js-main-nav').addClass('sticky');
                $('.js-main-container').css({
                    'margin-top' : marginTopMain + heightNav + 'px'
                });
            } else {
                $('.js-main-nav').removeClass('sticky');
                $('.js-main-container').css({
                    'margin-top' : marginTopMain + 'px'
                });
            }
        });

        // Main menu
        var menuButtonContainer = $('.js-main-nav .menu-container');
        var optionsContainer = $('.js-main-nav .options');
        $('.js-main-nav .js-expand-button').on('click', function(e){
            e.preventDefault();
            if (menuButtonContainer.hasClass('active')) {
                hideMenu();
            } else {
                showMenu();
            }
            return false;
        });

        // Smooth scrolling
        var $root = $('html, body');
        $('.js-smooth').click(function(){
            var distance = 0;
            if (windowTop <= offsetNav) {
                distance = $($.attr(this, 'href')).offset().top - $('.js-main-nav').outerHeight();
            } else {
                distance = $($.attr(this, 'href')).offset().top;
            }
            $root.animate({
                scrollTop: distance - heightNav + 'px'
            }, 1000);
            return false;
        });

        $('.js-main-nav .options a').on('click', function(e){
            if (menuButtonContainer.hasClass('active')) {
                hideMenu();
            }
        });
    }

    function hideMenu() {
        menuButtonContainer.removeClass('active');
        optionsContainer.slideUp(200).removeClass('active');
    }

    function showMenu() {
        menuButtonContainer.addClass('active');
        optionsContainer.slideDown(200).addClass('active');
    }
});
