$(document).ready(function(){
    $('.js-slick-auto').slick({
        autoplay: true,
        arrows: false,
        slidesToShow: 1,
        speed: 400,
        // centerMode: true,
        draggable: false,
        pauseOnFocus: false,
        pauseOnHover: false
    });

    $('.js-slick-full').slick({
        dots: true,
        autoplay: false,
        slidesToShow: 1,
        speed: 0,
        // arrows: true,
        draggable: false,
        pauseOnFocus: true,
        pauseOnHover: true,
    });
});
